<template>
    <div style="display: none">
        <slot v-if="ready"></slot>
    </div>
</template>
  
<script>
import L from 'leaflet'
import { IRouter, IGeocoder, LineOptions } from 'leaflet-routing-machine'
import { findRealParent } from 'vue2-leaflet'
import { mapState } from 'vuex'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import eventBus from '@/utils/eventBus'
import RoutePopup from './RoutePopup'
import Vue from 'vue'

const showLabel = (i) => {
    return i + 1
}

const props = {
    item: {
        type: Object,
        required: true
    },
    showRouting: {
        type: Boolean,
        default: true
    },
    router: {
        type: IRouter
    },
    plan: {
        type: L.Routing.Plan
    },
    geocoder: {
        type: IGeocoder
    },
    fitSelectedRoutes: {
        type: [String, Boolean],
        default: false
    },
    lineOptions: {
        type: LineOptions
    },
    routeLine: {
        type: Function
    },
    autoRoute: {
        type: Boolean,
        default: true
    },
    routeWhileDragging: {
        type: Boolean,
        default: false
    },
    routeDragInterval: {
        type: Number,
        default: 500
    },
    waypointMode: {
        type: String,
        default: 'connect'
    },
    useZoomParameter: {
        type: Boolean,
        default: false
    },
    showAlternatives: {
        type: Boolean,
        default: false
    },
    altLineOptions: {
        type: LineOptions
    },
    selectPoint: {
        type: Function,
        default: () => {}
    },
    pointDesc: {
        type: Function,
        default: () => {}
    },
    showMarkerPopup: {
        type: Boolean,
        default: true
    }
}

export default {
    props,
    name: 'LRoutingMachine',
    computed: {
        ...mapState({
            config: state => state.config.config,
            mapShowRouting: state => state.monitor.mapShowRouting
        }),
        routingLine() {
            return this.item.routingLine
        },
        routingPopup() {
            return this.item.showPopup
        },
        seriveUrl() {
            return this.config?.map?.serviceUrl ? this.config.map.serviceUrl : null
        },
        points () {
            if(this.item.routing?.length) {
                return this.item.routing
            }

            return []
        },
        waypoints() {
            if(this.points?.length) {
                const delivery_points = JSON.parse(JSON.stringify(this.points))
                const first = delivery_points.shift()
            
                let points = []

                points = [
                    first,
                    ...delivery_points
                ]

                return points
            } else
                return []
        },
        lineColor() {
            return this.item.color || '#000000'
        }
    },
    data() {
        return {
            parentContainer: null,
            ready: false,
            layer: null,
            markerList: []
        }
    },
    methods: {
        hideAllPopup() {
            if(this.markerList.length) {
                this.markerList.forEach(item => {
                    item.closePopup()
                })
            }
        },
        openAllPopup() {
            if(this.routingPopup && this.markerList.length) {
                this.markerList.forEach(item => {
                    item.openPopup()
                })
            }
        },
        add () {
            if (this.parentContainer._isMounted) {
                const {
                    waypoints,
                    fitSelectedRoutes,
                    autoRoute,
                    routeWhileDragging,
                    routeDragInterval,
                    waypointMode,
                    useZoomParameter,
                    showAlternatives
                } = this

                const options = {
                    language: 'ru',
                    waypoints,
                    fitSelectedRoutes,
                    autoRoute,
                    routeWhileDragging,
                    routeDragInterval,
                    draggableWaypoints: false,
                    addWaypoints: false,
                    waypointMode,
                    lineOptions: {
                        styles: [
                            {color: 'white', opacity: this.routingLine && this.mapShowRouting ? 0.8 : 0, weight: 7}, 
                            {color: this.lineColor, opacity: this.routingLine && this.mapShowRouting ? 1 : 0, weight: 6}]
                    },
                    collapsible: true,
                    show: false,
                    useZoomParameter,
                    collapseBtn: function(itinerary) {
                        var collapseBtn = L.DomUtil.create('span', itinerary.options.collapseBtnClass);
                        L.DomEvent.on(collapseBtn, 'click', itinerary._toggle, itinerary);
                        itinerary._container.insertBefore(collapseBtn, itinerary._container.firstChild);
                    },
                    createMarker:  (i, waypoint, n) => {
                        if(waypoints[i].is_start) {
                            return L.marker(waypoint.latLng, {
                                draggable: false,
                                bounceOnAdd: false,
                                icon: L.divIcon({
                                    className: 'map-marker',
                                    iconSize: null,
                                    html:`
                                    <div 
                                        class="map_stock_marker">
                                        <div 
                                            class="marker_circle" 
                                            style="background-color: ${this.lineColor}">
                                            <i class="fi fi-rr-home-location-alt"></i>
                                            <div class="marker_sup">
                                                ${showLabel(i)}
                                            </div>
                                        </div>
                                    </div>
                                `
                                })
                            }).bindTooltip(`Склад погрузки: ${waypoints[i].name}`)
                        } else {
                            const orders = waypoints[i]?.orders || []
                            let popup = null

                            if(orders?.length) {
                                const PopupComponent = Vue.extend(RoutePopup)
                                const instance = new PopupComponent({
                                    propsData: { waypoint: waypoints[i] }
                                })
                                instance.$mount()

                                popup = L.popup({
                                    autoClose: false, 
                                    closeButton: false,
                                    closeOnClick: false
                                })
                                    .setContent(instance.$el)
                            }

                            const marker = L.marker(waypoint.latLng, {
                                draggable: false,
                                bounceOnAdd: false,
                                icon: L.divIcon({
                                    className: 'map-marker',
                                    iconSize: null,
                                    html:`
                                    <div class="map_point_marker">
                                        <div 
                                            class="marker_circle" 
                                            style="background-color: ${this.lineColor}">
                                        </div>
                                        <div class="label">
                                            ${showLabel(i)}
                                        </div>
                                    </div>
                                `
                                })
                            })
                                .bindPopup(popup)

                            marker.on('mouseover', () => {
                                if(!this.showMarkerPopup || !this.routingPopup) {
                                    marker.openPopup()
                                }
                            })
                            marker.on('mouseout', () => {
                                if(!this.showMarkerPopup || !this.routingPopup) {
                                    marker.closePopup()
                                }
                            })
                            marker.on('popupopen', () => {
                                
                            })

                            this.markerList.push(marker)

                            return marker
                        }
                    }
                }

                if(this.seriveUrl)
                    options.serviceUrl = this.seriveUrl

                const { mapObject } = this.parentContainer
                const routingLayer = L.Routing.control(options)
                routingLayer.addTo(mapObject)
                this.layer = routingLayer

                if(this.routingPopup && this.showMarkerPopup && this.markerList.length) {
                    this.markerList.forEach(item => {
                        item.openPopup()
                    })
                }
            }
        },
        init() {
            this.parentContainer = findRealParent(this.$parent)
            this.add()
            this.ready = true
        }
    },
    mounted() {
        this.init()

        eventBus.$on(`toggle_map_popup_${this.item.id}`, value => {
            if(value) {
                if(this.showMarkerPopup)
                    this.openAllPopup()
            } else {
                this.hideAllPopup()
            }
        })
    },
    beforeDestroy() {
        eventBus.$off(`toggle_map_popup_${this.item.id}`)
        return this.layer ? this.layer.remove() : null
    }
}
</script>

<style lang="scss">
.leaflet-routing-container-hide .leaflet-routing-collapse-btn{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKZSURBVFiFvdZfqI1ZGAbwnz85/tWhRNSJ5pJGEYkRLiijIblyhTSXw7hz4YZC4+JopFxIioQSYeZippAyF9PkhoiLM2WGaUgNOeYP5pztYq3dWXud7+z9ne07nlrtvu991vs8+1vrXe+iNT7DOTzBm/h7FstKzP0gjMFR1JqMI5E3Ivi2hXh9dI+E+GL0Z0LP8RP+yt73YWHVBk5kIqcwPsYm42IWP161gQdJ8heYEA2sx1h0ChuyzrlftYGnSfLb8d2a+Lw4PvcknKdVG7ibJH+NqRiNTzEKs/B/wrlTtYGrGtf4R8yIsS7cyuKXqzawIxOo7/Y/DK6OGr6q2sDsKFjmHOiL/MpxuaSBSyMhDksVf+509GPJSBkgNJ1mBs4MMW8a1mEzVhk4xIaNWcIRXCT+HDMz/hxc0FiiNfTiG0xsx8TnBi9FPzZkvEVNzKaH2tR2TBzKEh3M4lOEEi2zaa+2Y2CMgQb0ndAPUuzLRP4R2vlOXCkwsbKM6HRh3c5HAx1CP+go4D5Mkr8TKijFwczAsWbCM4Wbzr+R/BIrmvDHajy0rsf3C4U23iEsUbqPbhYlmobDwuer4RX2a71pxmUGvo/v1wpNqlNo5+8Szq2iRD8khBvRUFn8mcztFcoxxZcal+BsUZKNeJSQfsYmoQ23wrVM4BG2CUu3D/9l8T1DJRot3Hp+Sci/4mvhMw6FXZlAqzG/xJ+yXCi5+qRn2Kt4T3RpXONm40EZ8RQLcDoR6BWqpCvjnSlpYPtwDdTxSRSuV8mbaGxujM/F2xbiPYrPkWFhBg4It+WaUILrYqy7hYEvPlQ8xWRhc/6O3+LzJOGKXiR+skrxFOOwNQ6Yh78z8XvabMXtYksi/srAPvmo2I3HWN2M9B6A4E5bsqvdNAAAAABJRU5ErkJggg==');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0px;
    top: 0px;
    opacity: 0.6;
    cursor: pointer;
}
.map_stock_marker{
    width: 37px;
    height: 37px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    text-align: center;
    .marker_circle{
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 18px;
        position: relative;
        .marker_sup{
            background: #fff;
            width: 16px;
            height: 16px;
            color: #000000;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: -5px;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        }
    }
}
.map_point_marker{
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 30px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    .marker_circle{
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: rgb(29, 101, 192);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
    .label{
        font-size: 16px;
        padding-left: 6px;
        padding-right: 6px;
    }
}
</style>